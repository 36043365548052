import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload, Select, InputNumber, Popconfirm, Switch } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate, Link } from 'react-router-dom'
import ReactQuill from 'react-quill'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { UPDATE_ONE_OBJECT, FIND_UNIQUE_OBJECT, DELETE_ONE_OBJECT, FIND_MANY_COMPANY } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const tags = [
    {
        label: 'Мангальная зона',
        value: 'grill'
    },
    {
        label: 'Баня/парилка/сауна',
        value: 'bathhouse'
    },
    {
        label: 'Гараж',
        value: 'garage'
    },
    {
        label: 'Портативный гараж',
        value: 'portable garage'
    },
    {
        label: 'Детская площадка',
        value: 'playground'
    },
    {
        label: 'Беседка',
        value: 'gazebo'
    },
    {
        label: 'Бассейн',
        value: 'pool'
    },
    {
        label: 'Рядом с рекой/озером',
        value: 'river'
    },
    {
        label: 'Трансфер',
        value: 'transfer'
    },
    {
        label: 'Можно с питомцами',
        value: 'pet'
    },
    {
        label: 'Кондиционер',
        value: 'conditioner'
    },
    {
        label: 'Wi-Fi',
        value: 'wifi'
    },
    {
        label: 'Санузел',
        value: 'bathroom'
    },
    {
        label: 'Плита для варки',
        value: 'stove'
    },
    {
        label: 'Холодильник',
        value: 'fridge'
    },
    {
        label: 'Микроволновая печь',
        value: 'microwave'
    },
    {
        label: 'Посуда/приборы',
        value: 'dishes'
    },
    {
        label: 'Питьевая вода',
        value: 'water'
    },
    {
        label: 'Караоке',
        value: 'karaoke'
    },
    {
        label: 'Телевизор',
        value: 'tv'
    },
    {
        label: 'Проектор',
        value: 'projector'
    },
    {
        label: 'Игровая зона в доме',
        value: 'gamezone'
    },
    {
        label: 'Постельное белье',
        value: 'bedding'
    },
    {
        label: 'Завтрак включен',
        value: 'breakfast'
    },
    {
        label: 'Электрическое отопление',
        value: 'electric heat'
    },
    {
        label: 'Отопление',
        value: 'heat'
    },
]

const places = [
    {
        label: 'г. Якутск',
        value: 'yakutsk'
    },
    {
        label: 'г. Якутск, Вилюйский тракт',
        value: 'vilyuyskiy'
    },
    {
        label: 'г. Якутск, Маганский тракт',
        value: 'maganskiy'
    },
    {
        label: 'г. Якутск, Намский тракт',
        value: 'namskiy'
    },
    {
        label: 'г. Якутск, Намцырский тракт',
        value: 'namtsyrskiy'
    },
    {
        label: 'г. Якутск, Покровский тракт',
        value: 'pokrovskiy'
    },
    {
        label: 'г. Якутск, Хатын-Юряхское шоссе',
        value: 'khatyn-yuryakhskoye'
    },
    {
        label: 'г. Якутск, мкр. Кангалассы',
        value: 'khangalassy'
    },
    {
        label: 'г. Якутск, с. Пригородный',
        value: 'prigorodniy'
    },
    {
        label: 'г. Якутск, с. Старая Табага',
        value: 'tabaga'
    },
    {
        label: 'г. Якутск, с. Хатассы',
        value: 'khatassy'
    },
    {
        label: 'Амгинский район',
        value: 'amginskiy-rayon'
    },
    {
        label: 'Горный район',
        value: 'gorny-rayon'
    },
    {
        label: 'Мегино-Кангаласский район',
        value: 'megino-kangalasskiy-rayon'
    },
    {
        label: 'Намский район',
        value: 'namskiy-rayon'
    },
    {
        label: 'Нерюнгринский район',
        value: 'nerungrinskiy-rayon'
    },
    {
        label: 'Хангаласский район',
        value: 'khangalasskiy-rayon'
    }
]

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false
    }
}
const HOST_NAME = process.env.REACT_APP_HOST_NAME

const DraggableUploadListItem = ({ originNode, file }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: file.uid,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
    }
    return (
        <div
            ref={setNodeRef}
            style={style}
            className={isDragging ? 'is-dragging' : ''}
            {...attributes}
            {...listeners}
        >
            {file.status === 'error' && isDragging ? originNode.props.children : originNode}
        </div>
    )
}

const Object = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()

    const [images, setImages] = useState([])
    const [images_720, setImages720] = useState([])
    const [images_1024, setImages1024] = useState([])
    const [companies, setCompanies] = useState([])

    useQuery(FIND_MANY_COMPANY, {
        fetchPolicy: 'network-only',
        onCompleted: ({ findManyCompany }) => {
            const companiesData = findManyCompany.map(object => ({
                value: object.id,
                label: object.name
            }))
            setCompanies(companiesData)
        }
    })

    const { loading, error } = useQuery(FIND_UNIQUE_OBJECT, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueObject }) => {
            form.setFieldsValue({
                name: findUniqueObject.name,
                description: findUniqueObject.description,
                company: findUniqueObject.companyId,
                premium: findUniqueObject.premium,
                publish: findUniqueObject.publish,
                people: findUniqueObject.people,
                peopleInfo: findUniqueObject.peopleInfo,
                bed: findUniqueObject.bed,
                bedInfo: findUniqueObject.bedInfo,
                square: findUniqueObject.square,
                location: findUniqueObject.location,
                place: findUniqueObject.place || undefined,
                address: findUniqueObject.address,
                comforts: findUniqueObject.comforts,
                tags: findUniqueObject.tags,
                booking: findUniqueObject.booking,
                rules: findUniqueObject.rules,
                videos: findUniqueObject.videos,
                heat: findUniqueObject.heat
            })
            const objectImages = findUniqueObject?.images?.map((object, index) => ({
                uid: index,
                name: object.replace(/\.[^/.]+$/, ""),
                status: 'done',
                url: HOST_NAME + `uploads/${object}`,
                response: {
                    filename: object
                }
            }))
            setImages(objectImages)
            const objectImages720 = findUniqueObject?.images_720?.map((object, index) => ({
                uid: index,
                name: object.replace(/\.[^/.]+$/, ""),
                status: 'done',
                url: HOST_NAME + `uploads/${object}`,
                response: {
                    filename: object
                }
            }))
            setImages720(objectImages720)
            const objectImages1024 = findUniqueObject?.images_1024?.map((object, index) => ({
                uid: index,
                name: object.replace(/\.[^/.]+$/, ""),
                status: 'done',
                url: HOST_NAME + `uploads/${object}`,
                response: {
                    filename: object
                }
            }))
            setImages1024(objectImages1024)
        },
    })

    const postImageCompress = async () => {
        await fetch(HOST_NAME + "compress-image/", {
            method: "POST",
            headers: {
                "Accept": "*/*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Method": "POST",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                photo_names: images.map((i) => i.response.filename),
            }),
        })
            .then((response) => response.json())
            .then((responseData) => {
                updateImageOneObjectMutation({
                    variables: {
                        where: {
                            id
                        },
                        data: {
                            images_720: responseData['filenames_720'],
                            images_1024: responseData['filenames_1024']
                        }
                    }
                })
            })
    }

    const [resetPhotoListOneObjectMutation, { }] = useMutation(UPDATE_ONE_OBJECT, {
        onCompleted: () => {
            message.success('Список картинок обновлена')
            postImageCompress()
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [updateOneObjectMutation, { loading: updateOneObjectLoading }] = useMutation(UPDATE_ONE_OBJECT, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [updateImageOneObjectMutation, { }] = useMutation(UPDATE_ONE_OBJECT, {
        onCompleted: () => {
            message.success('Сжатие картинок прошла успешно')
            window.location.reload()
        },
        onError: e => {
            message.error('Что то пошло не так при сжатии картинок, повторите попытку позже')
        }
    })

    const [deleteOneObjectMutation] = useMutation(DELETE_ONE_OBJECT, {
        onCompleted: () => {
            message.success('Обьект удален')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        }
    })

    if (loading || error) return null

    const handleSubmit = async (values) => {
        const files = images.map(object => object.response.filename) || []
        const files720 = images_720.map(object => object.response.filename) || []
        const files1024 = images_1024.map(object => object.response.filename) || []

        await updateOneObjectMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    name: {
                        set: values.name
                    },
                    description: {
                        set: values.description
                    },
                    premium: {
                        set: values.premium
                    },
                    publish: {
                        set: values.publish
                    },
                    company: {
                        connect: {
                            id: values.company
                        }
                    },
                    images: files,
                    images_720: files720,
                    images_1024: files1024,
                    location: values.location ? {
                        set: values.location
                    } : undefined,
                    place: values.place ? {
                        set: values.place
                    } : undefined,
                    address: values.address ? {
                        set: values.address
                    } : undefined,
                    people: values.people ? {
                        set: values.people
                    } : undefined,
                    peopleInfo: values.peopleInfo ? {
                        set: values.peopleInfo
                    } : undefined,
                    bed: values.bed ? {
                        set: values.bed
                    } : undefined,
                    bedInfo: values.bedInfo ? {
                        set: values.bedInfo
                    } : undefined,
                    square: values.square ? {
                        set: values.square
                    } : undefined,
                    booking: values.booking ? {
                        set: values.booking
                    } : undefined,
                    rules: values.rules ? {
                        set: values.rules
                    } : undefined,
                    heat: values.heat ? {
                        set: values.heat
                    } : undefined,
                    comforts: values.comforts,
                    tags: values.tags,
                    videos: values.videos,
                }
            }
        })
    }


    const resetPhotoList = async () => {
        await resetPhotoListOneObjectMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    images_720: [],
                    images_1024: []
                }
            }
        })
    }

    const onDelete = async () => {
        await deleteOneObjectMutation({
            variables: {
                where: {
                    id
                }
            }
        })
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(HOST_NAME + `uploads/${name}`, '_blank')
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setImages((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id)
                const overIndex = prev.findIndex((i) => i.uid === over?.id)
                return arrayMove(prev, activeIndex, overIndex)
            })
            if (images_720.length > 0)
                setImages720((prev) => {
                    const activeIndex = prev.findIndex((i) => i.uid === active.id)
                    const overIndex = prev.findIndex((i) => i.uid === over?.id)
                    return arrayMove(prev, activeIndex, overIndex == -1 ? 0 : overIndex)
                })
            if (images_1024.length > 0)
                setImages1024((prev) => {
                    const activeIndex = prev.findIndex((i) => i.uid === active.id)
                    const overIndex = prev.findIndex((i) => i.uid === over?.id)
                    return arrayMove(prev, activeIndex, overIndex == -1 ? 0 : overIndex)
                })
        }
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item label='Изображение' >
                    {images_720.length != images.length ?
                        <Button onClick={postImageCompress}>
                            Image compress
                        </Button> : ""
                    }
                    <Button onClick={resetPhotoList}>
                        Обнуление списка картинок
                    </Button>
                    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                        <SortableContext items={images.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                            <Upload
                                multiple
                                action={HOST_NAME + 'upload'}
                                listType='picture'
                                fileList={images}
                                onChange={({ fileList }) => {
                                    setImages(fileList)
                                    if (fileList.length > images_720.length) {
                                        var lastItem = fileList.slice(-1)[0];
                                        if (lastItem.status == 'done') {
                                            images_720.push(
                                                {
                                                    uid: images_720.length,
                                                    name: lastItem.response.filename_720.replace(/\.[^/.]+$/, ""),
                                                    status: 'done',
                                                    url: HOST_NAME + `uploads/${lastItem.response.filename_720}`,
                                                    response: {
                                                        filename: lastItem.response.filename_720
                                                    }
                                                }
                                            )
                                            setImages720(images_720)

                                            images_1024.push(
                                                {
                                                    uid: images_1024.length,
                                                    name: lastItem.response.filename_1024.replace(/\.[^/.]+$/, ""),
                                                    status: 'done',
                                                    url: HOST_NAME + `uploads/${lastItem.response.filename_1024}`,
                                                    response: {
                                                        filename: lastItem.response.filename_1024
                                                    }
                                                }
                                            )
                                            setImages1024(images_1024)
                                        }
                                    } else {
                                        var name_list = fileList.map(object => object.name)
                                        const localImageList = images_720
                                        localImageList.forEach(function (element, index) {
                                            const filename = element.name.replace("_720", "")
                                            if (name_list.indexOf(filename) == -1) {
                                                images_720.splice(index, 1);
                                                images_1024.splice(index, 1);
                                            }
                                        })
                                        setImages720(images_720)
                                        setImages1024(images_1024)
                                    }
                                }}
                                onPreview={onPreview}
                                itemRender={(originNode, file) => (
                                    <DraggableUploadListItem originNode={originNode} file={file} />
                                )}
                            >
                                <Button icon={<UploadOutlined />}>+ Загрузить</Button>
                            </Upload>
                        </SortableContext>
                    </DndContext>
                </Form.Item>
                <Form.Item name='videos' label='Видео'>
                    <Select mode='tags' placeholder='Введите ссылку youtube' />
                </Form.Item>
                <Form.Item name='company' label='Компания'>
                    <Select
                        placeholder='Выберите компанию'
                        options={companies}
                    />
                </Form.Item>
                <Form.Item name='name' label='Название обьекта'>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item name='description' label='Описание'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item name='booking' label='Бронирование и оплата'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item name='rules' label='Правила'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item name='location' label='Локация'>
                    <Input placeholder='Введите url с 2gis' />
                </Form.Item>
                <Form.Item name='place' label='Расположение'>
                    <Select placeholder='Расположение' options={places} />
                </Form.Item>
                <Form.Item name='address' label='Адрес'>
                    <Input placeholder='Введите адрес' />
                </Form.Item>
                <Form.Item name='people' label='Количество гостей'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите количество гостей' />
                </Form.Item>
                <Form.Item name='peopleInfo' label='Описание о количество гостей'>
                    <Input placeholder='Введите описание о количество гостей' />
                </Form.Item>
                <Form.Item name='bed' label='Спальные места'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите количество спальных мест' />
                </Form.Item>
                <Form.Item name='bedInfo' label='Описание о спальных мест'>
                    <Input placeholder='Введите описание о спальных мест' />
                </Form.Item>
                <Form.Item name='square' label='Площадь'>
                    <Input placeholder='Введите площадь' />
                </Form.Item>
                <Form.Item name='heat' label='Отопление'>
                    <Input placeholder='Введите описание' />
                </Form.Item>
                <Form.Item name='tags' label='Тэги'>
                    <Select
                        mode='multiple'
                        placeholder='Тэги'
                        optionFilterProp='label'
                        options={tags}
                    />
                </Form.Item>
                <Form.Item name='comforts' label='Удобства'>
                    <Select
                        mode='tags'
                        placeholder='Удобства'
                        optionFilterProp='label'
                    />
                </Form.Item>
                <Form.Item name='premium' label='Интересно'>
                    <Switch />
                </Form.Item>
                <Form.Item name='publish' label='Опубликовано'>
                    <Switch />
                </Form.Item>
                <Button loading={updateOneObjectLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button style={{ marginLeft: 5, marginRight: 5 }} type='primary' danger>
                        Удалить
                    </Button>
                </Popconfirm>
                <Link to='schedules'>
                    <Button>
                        График
                    </Button>
                </Link>
            </Form>
        </>
    )
}

export default Object
